import {Category} from '../../models/team';

export enum ParticipantGroup {
    Boys = 'boys',
    Girls = 'girls',
    Men = 'men',
    Women = 'women',
    Open = 'open',
    Mixed = 'mixed',
    Mixed18 = 'mixed18',
}

export class BoatType {
    public category: Category;
    public numberOfRowers: number;
    public hasCoxswain: boolean;
    public participantGroup: ParticipantGroup;

    constructor(category: Category, numberOfRowers: number, hasCoxswain: boolean, participantGroup: ParticipantGroup) {
        this.category = category;
        this.numberOfRowers = numberOfRowers;
        this.hasCoxswain = hasCoxswain;
        this.participantGroup = participantGroup;
    }
}
