import {Category} from '@material-ui/icons';
import {Tag} from './tag';

export const categories: Category[] = [
    // Skiff
    'H 1x-',
    'D 1x-',
    'J18 1x-',
    'M18 1x-',

    // Twee-zonder
    'H 2-',
    'D 2-',
    'MX 2-',
    'J18 2-',
    'M18 2-',
    'MX18 2-',

    // Dubbel-twee
    'H 2x-',
    'D 2x-',
    'MX 2x-',
    'J18 2x-',
    'M18 2x-',
    'MX18 2x-',
    'J16 2x-',
    'M16 2x-',

    // Dubbelvier-met
    'H 4x+',
    'D 4x+',
    'O 4x+',
    'MX 4x+',
    'J18 4x+',
    'M18 4x+',
    'MX18 4x+',
    'J16 4x+',
    'M16 4x+',

    // Vier-met
    'H 4+',
    'D 4+',
    'O 4+',
    'MX 4+',
    'J18 4+',
    'M18 4+',
    'MX18 4+',

    // Vier-zonder
    'H 4-',
    'D 4-',
    'O 4-',
    'MX 4-',
    'J18 4-',
    'M18 4-',
    'MX18 4-',

    // Dubbelvier-zonder
    'H 4x-',
    'D 4x-',
    'O 4x-',
    'MX 4x-',
    'J18 4x-',
    'M18 4x-',
    'MX18 4x-',

    // Dubbelacht
    'H 8x+',
    'D 8x+',
    'O 8x+',
    'MX 8x+',
    'J18 8x+',
    'M18 8x+',
    'MX18 8x+',

    // Acht
    'H 8+',
    'D 8+',
    'O 8+',
    'MX 8+',
    'J18 8+',
    'M18 8+',
    'MX18 8+',

    // C-dubbeltwee
    'H C2x+',
    'D C2x+',
    'O C2x+',
    'MX C2x+',
    'J18 C2x+',
    'M18 C2x+',
    'MX18 C2x+',

    // C-dubbeltwee-zonder
    'H C2x-',
    'D C2x-',
    'O C2x-',
    'MX C2x-',
    'J18 C2x-',
    'M18 C2x-',
    'MX18 C2x-',

    // C-dubbelvier
    'H C4x+',
    'D C4x+',
    'O C4x+',
    'MX C4x+',
    'J18 C4x+',
    'M18 C4x+',
    'MX18 C4x+',

    // C-vier
    'H C4+',
    'D C4+',
    'O C4+',
    'MX C4+',
    'J18 C4+',
    'M18 C4+',
    'MX18 C4+',
];

export type Category =
    // Skiff
    | 'H 1x-'
    | 'D 1x-'
    | 'J18 1x-'
    | 'M18 1x-'

    // Twee-zonder
    | 'H 2-'
    | 'D 2-'
    | 'MX 2-'
    | 'J18 2-'
    | 'M18 2-'
    | 'MX18 2-'

    // Dubbel-twee
    | 'H 2x-'
    | 'D 2x-'
    | 'MX 2x-'
    | 'J18 2x-'
    | 'M18 2x-'
    | 'MX18 2x-'
    | 'J16 2x-'
    | 'M16 2x-'

    // Dubbelvier-met
    | 'H 4x+'
    | 'D 4x+'
    | 'O 4x+'
    | 'MX 4x+'
    | 'O18 4x+'
    | 'J18 4x+'
    | 'M18 4x+'
    | 'MX18 4x+'
    | 'J16 4x+'
    | 'M16 4x+'

    // Vier-met
    | 'H 4+'
    | 'D 4+'
    | 'O 4+'
    | 'MX 4+'
    | 'J18 4+'
    | 'M18 4+'
    | 'MX18 4+'

    // Vier-zonder
    | 'H 4-'
    | 'D 4-'
    | 'O 4-'
    | 'MX 4-'
    | 'J18 4-'
    | 'M18 4-'
    | 'MX18 4-'

    // Dubbelvier-zonder
    | 'H 4x-'
    | 'D 4x-'
    | 'O 4x-'
    | 'MX 4x-'
    | 'J18 4x-'
    | 'M18 4x-'
    | 'MX18 4x-'

    // Dubbelacht
    | 'H 8x+'
    | 'D 8x+'
    | 'O 8x+'
    | 'MX 8x+'
    | 'J18 8x+'
    | 'M18 8x+'
    | 'MX18 8x+'

    // Acht
    | 'H 8+'
    | 'D 8+'
    | 'O 8+'
    | 'MX 8+'
    | 'J18 8+'
    | 'M18 8+'
    | 'MX18 8+'

    // C-dubbeltwee
    | 'H C2x+'
    | 'D C2x+'
    | 'O C2x+'
    | 'MX C2x+'
    | 'J18 C2x+'
    | 'M18 C2x+'
    | 'MX18 C2x+'

    // C-dubbeltwee-zonder
    | 'H C2x-'
    | 'D C2x-'
    | 'O C2x-'
    | 'MX C2x-'
    | 'J18 C2x-'
    | 'M18 C2x-'
    | 'MX18 C2x-'

    // C-dubbelvier
    | 'H C4x+'
    | 'D C4x+'
    | 'O C4x+'
    | 'MX C4x+'
    | 'J18 C4x+'
    | 'M18 C4x+'
    | 'MX18 C4x+'

    // C-vier
    | 'H C4+'
    | 'D C4+'
    | 'O C4+'
    | 'MX C4+'
    | 'J18 C4+'
    | 'M18 C4+'
    | 'MX18 C4+';

export type GenderClass =
    | 'Heren'
    | 'Dames'
    | 'Open'
    | 'Open jeugd 18'
    | 'Jongens 18'
    | 'Meiden 18'
    | 'Mix'
    | 'Mix jeugd 18'
    | 'Jongens 16'
    | 'Meiden 16';

export const categoryToGenderClassMapping: {[category in Category]: GenderClass[]} = {
    // Skiff
    'H 1x-': ['Heren'],
    'D 1x-': ['Dames'],
    'J18 1x-': ['Jongens 18'],
    'M18 1x-': ['Meiden 18'],

    // Twee-zonder
    'H 2-': ['Heren'],
    'D 2-': ['Dames'],
    'MX 2-': ['Mix'],
    'J18 2-': ['Jongens 18'],
    'M18 2-': ['Meiden 18'],
    'MX18 2-': ['Mix jeugd 18'],

    // Dubbel-twee
    'H 2x-': ['Heren'],
    'D 2x-': ['Dames'],
    'MX 2x-': ['Mix'],
    'J18 2x-': ['Jongens 18'],
    'M18 2x-': ['Meiden 18'],
    'MX18 2x-': ['Mix jeugd 18'],
    'J16 2x-': ['Jongens 16'],
    'M16 2x-': ['Meiden 16'],

    // Dubbelvier-met
    'H 4x+': ['Heren'],
    'D 4x+': ['Dames'],
    'O 4x+': ['Open'],
    'MX 4x+': ['Mix'],
    'O18 4x+': ['Open jeugd 18'],
    'J18 4x+': ['Jongens 18'],
    'M18 4x+': ['Meiden 18'],
    'MX18 4x+': ['Meiden 18'],
    'J16 4x+': ['Jongens 16'],
    'M16 4x+': ['Meiden 16'],

    // Vier-met
    'H 4+': ['Heren'],
    'D 4+': ['Dames'],
    'O 4+': ['Open'],
    'MX 4+': ['Mix'],
    'J18 4+': ['Jongens 18'],
    'M18 4+': ['Meiden 18'],
    'MX18 4+': ['Mix jeugd 18'],

    // Vier-zonder
    'H 4-': ['Heren'],
    'D 4-': ['Dames'],
    'O 4-': ['Open'],
    'MX 4-': ['Mix'],
    'J18 4-': ['Jongens 18'],
    'M18 4-': ['Meiden 18'],
    'MX18 4-': ['Mix jeugd 18'],

    // Dubbelvier-zonder
    'H 4x-': ['Heren'],
    'D 4x-': ['Dames'],
    'O 4x-': ['Open'],
    'MX 4x-': ['Mix'],
    'J18 4x-': ['Jongens 18'],
    'M18 4x-': ['Meiden 18'],
    'MX18 4x-': ['Mix jeugd 18'],

    // Dubbelacht
    'H 8x+': ['Heren'],
    'D 8x+': ['Dames'],
    'O 8x+': ['Open'],
    'MX 8x+': ['Mix'],
    'J18 8x+': ['Jongens 18'],
    'M18 8x+': ['Meiden 18'],
    'MX18 8x+': ['Mix jeugd 18'],

    // Acht
    'H 8+': ['Heren'],
    'D 8+': ['Dames'],
    'O 8+': ['Open'],
    'MX 8+': ['Mix'],
    'J18 8+': ['Jongens 18'],
    'M18 8+': ['Meiden 18'],
    'MX18 8+': ['Mix jeugd 18'],

    // C-dubbeltwee
    'H C2x+': ['Heren'],
    'D C2x+': ['Dames'],
    'O C2x+': ['Open'],
    'MX C2x+': ['Mix'],
    'J18 C2x+': ['Jongens 18'],
    'M18 C2x+': ['Meiden 18'],
    'MX18 C2x+': ['Mix jeugd 18'],

    // C-dubbeltwee-zonder
    'H C2x-': ['Heren'],
    'D C2x-': ['Dames'],
    'O C2x-': ['Open'],
    'MX C2x-': ['Mix'],
    'J18 C2x-': ['Jongens 18'],
    'M18 C2x-': ['Meiden 18'],
    'MX18 C2x-': ['Mix jeugd 18'],

    // C-dubbelvier
    'H C4x+': ['Heren'],
    'D C4x+': ['Dames'],
    'O C4x+': ['Open'],
    'MX C4x+': ['Mix'],
    'J18 C4x+': ['Jongens 18'],
    'M18 C4x+': ['Meiden 18'],
    'MX18 C4x+': ['Mix jeugd 18'],

    // C-vier
    'H C4+': ['Heren'],
    'D C4+': ['Dames'],
    'O C4+': ['Open'],
    'MX C4+': ['Mix'],
    'J18 C4+': ['Jongens 18'],
    'M18 C4+': ['Meiden 18'],
    'MX18 C4+': ['Mix jeugd 18'],
};

export interface Team {
    id: string;
    editionId: string;
    heatId: string | null;
    isAttending: boolean;
    isInCompetition: boolean;
    notes: string;
    recordedStartTimeId: string | null;
    recordedFinishTimeId: string | null;
    number: number | null;
    name: string;
    clubCode: string;
    boat: string;
    category: Category;
    tags: Tag[];
    coxswainId: string | null;
    rower1Id: string | null;
    rower2Id: string | null;
    rower3Id: string | null;
    rower4Id: string | null;
    rower5Id: string | null;
    rower6Id: string | null;
    rower7Id: string | null;
    rower8Id: string | null;
    isQualified: boolean;
}
