import {EditionRepository} from '../../persistence/edition/edition_repository';
import * as randomstring from 'randomstring';
import {Edition, RegattaMode} from '../../../models/edition';
import {HeatRepository} from '../../persistence/heat/heat_repository';

export class EditionInteractor {
    private _editionRepository: EditionRepository;
    private _heatRepository: HeatRepository;

    constructor(editionRepository: EditionRepository, heatRepository: HeatRepository) {
        this._editionRepository = editionRepository;
        this._heatRepository = heatRepository;
    }

    public create(name: string, handicapsSystem: string, mode: RegattaMode): Promise<Edition> {
        const token = randomstring.generate({
            length: 5,
            charset: 'bcdfghjklmnpqrstvwxyz',
            capitalization: 'uppercase',
        });
        return this._editionRepository.create(name, token, handicapsSystem, mode).then(res => {
            this._heatRepository.create(res.id, 'Heat 1', 1);
            return res;
        });
    }

    public async findByToken(token: string): Promise<Edition | null> {
        return await this._editionRepository.findByToken(token);
    }
}
