import * as React from 'react';
import {InschrijfTafelPresenter} from './inschrijf_tafel_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import Loading from '../../../components/loading';
import {Edition} from '../../../../../models/edition';
import {observer} from 'mobx-react';
import PrintPage from '../../../components/print_page';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {Participant} from '../../../../../models/participant';
import {decodeClubCode} from '../../../../support/club_code_decoder';
import {Team} from '../../../../../models/team';
import Grid from '@material-ui/core/Grid/Grid';

interface OwnProps {
    edition: Edition;
    sortByName: boolean;
}

@observer
class InschrijfTafelPrint extends React.Component<OwnProps & PresenterProps<InschrijfTafelPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return (
                <Grid container>
                    <Loading />
                </Grid>
            );
        }
        const compareName = function(a: Team, b: Team): number {
            if (a.name === null) {
                return 1;
            } else if (b.name === null) {
                return -1;
            } else {
                return a.name.localeCompare(b.name);
            }
        };
        const compareNumber = function(a: Team, b: Team): number {
            if (a.number === null) {
                return 1;
            } else if (b.number === null) {
                return -1;
            } else if (a.number === b.number) {
                return 0;
            } else if (a.number < b.number) {
                return -1;
            } else {
                return 1;
            }
        };
        let teams = this.props.presenter.teams;
        if (this.props.sortByName) {
            teams = teams.sort(compareName);
        } else {
            teams = teams.sort(compareNumber);
        }
        return (
            <>
                {teams.sort(compareNumber).map(team => {
                    const heat = this.props.presenter.heats.find(h => h.id === team.heatId);
                    return (
                        <PrintPage>
                            <Grid
                                container
                                key={team.id}
                                style={{
                                    pageBreakAfter: 'always',
                                }}>
                                <Grid item xs={2} style={{fontSize: 20, color: '#696969'}}>
                                    Rug
                                </Grid>
                                <Grid item xs={2} style={{fontSize: 20, color: '#696969'}}>
                                    Wedstrijdtype
                                </Grid>
                                <Grid item xs={4} style={{fontSize: 20, color: '#696969'}}>
                                    Boot
                                </Grid>
                                <Grid item xs={4} style={{fontSize: 20, color: '#696969'}}>
                                    Vereniging
                                </Grid>
                                <Grid item xs={2} style={{fontSize: 20, fontWeight: 'bold', marginBottom: 20}}>
                                    {team.number ? team.number : '?'}
                                </Grid>
                                <Grid item xs={2} style={{fontSize: 20, fontWeight: 'bold', marginBottom: 20}}>
                                    {team.category}
                                </Grid>
                                <Grid item xs={4} style={{fontSize: 20, fontWeight: 'bold', marginBottom: 20}}>
                                    {team.boat}
                                </Grid>
                                <Grid item xs={4} style={{fontSize: 20, fontWeight: 'bold', marginBottom: 20}}>
                                    {decodeClubCode(team.clubCode)}
                                </Grid>
                                <Grid item xs={4}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Inschrijfgeld betaald?</TableCell>
                                                <TableCell>&#9633;</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Borg betaald?</TableCell>
                                                <TableCell>&#9633;</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Borg retour?</TableCell>
                                                <TableCell>&#9633;</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Heat</TableCell>
                                                <TableCell>{heat ? heat.name : '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Stuur</TableCell>
                                                <TableCell>{this.coxswain(team)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={2} style={{margin: 15, fontSize: 20, color: '#696969'}}>
                                            Roeiers
                                        </Grid>
                                        <Grid item xs={8}>
                                            <ul style={{listStyleType: 'none', margin: 0}}>
                                                {this.rowers(team).map((participant, index) => {
                                                    return (
                                                        <li key={participant} style={{margin: 15, fontSize: 20}}>
                                                            {participant}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </PrintPage>
                    );
                })}
            </>
        );
    }

    private rowers(team: Team): string[] {
        return [
            team.rower1Id,
            team.rower2Id,
            team.rower3Id,
            team.rower4Id,
            team.rower5Id,
            team.rower6Id,
            team.rower7Id,
            team.rower8Id,
        ]
            .filter<string>((id: string | null): id is string => id !== null)
            .map((participantId: string) =>
                this.props.presenter.participants.find(participant => participant.id === participantId),
            )
            .filter<Participant>((participant): participant is Participant => participant !== undefined)
            .map(
                (participant: Participant, index: number) =>
                    '' +
                    (index + 1) +
                    ': ' +
                    participant.name +
                    ' (' +
                    participant.birthYear +
                    ') ' +
                    this.renderGender(participant),
            );
    }

    private coxswain(team: Team): string {
        if (team.coxswainId === null) {
            return '-';
        }
        const coxswain = this.props.presenter.participants.find(participant => participant.id === team.coxswainId);

        return coxswain ? coxswain.name : '-';
    }

    private renderGender(participant: Participant) {
        switch (participant.gender) {
            case 'Male':
                return 'M';
            case 'Female':
                return 'V';
            default:
                return '?';
        }
    }
}

export default withPresenter<InschrijfTafelPresenter, OwnProps>(
    (props, component) =>
        new InschrijfTafelPresenter(
            props.edition,
            component.persistence.teamRepository,
            component.persistence.heatRepository,
            component.persistence.participantRepository,
        ),
    InschrijfTafelPrint,
);
